export const CafeList = {
  cafe1: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>STARBUCKS</td>
        </tr>
        <tr className="head">
          <td>Menu</td>
          <td>MRP</td>
          <td>GM / ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>HOT COFFEE</td>
        </tr>
        <tr>
          <td>FLAT WHITE</td>
          <td className="ft-Wt-700"> 280 </td>
          <td className="ft-Wt-700">237ML</td>
        </tr>
        <tr>
          <td>CAFÉ MOCHA (WITHOUT CREAM)</td>
          <td className="ft-Wt-700"> 310 </td>
          <td className="ft-Wt-700">237ML</td>
        </tr>
        <tr>
          <td>CAFÉ LATTE</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">237ML</td>
        </tr>
        <tr>
          <td>CAPPUCCINO </td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">237ML</td>
        </tr>
        <tr>
          <td>CAFÉ AMERICANO</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">237ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>HOT TEA</td>
        </tr>
        <tr>
          <td>EMPEROR'S CLOUD MIST (GREEN TEA)</td>
          <td className="ft-Wt-700"> 310 </td>
          <td className="ft-Wt-700">237ML</td>
        </tr>
        <tr>
          <td>CHAI TEA LATTE</td>
          <td className="ft-Wt-700"> 280 </td>
          <td className="ft-Wt-700">237ML</td>
        </tr>
        <tr>
          <td>ENGLISH BREAKFAST TEA (BLACK TEA)</td>
          <td className="ft-Wt-700"> 300 </td>
          <td className="ft-Wt-700">237ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>FRAPPUCCINO\COLD COFFEE</td>
        </tr>
        <tr>
          <td>JAVACHIP FRAPPUCCINO (WITHOUT CREAM)</td>
          <td className="ft-Wt-700"> 330 </td>
          <td className="ft-Wt-700">354ML</td>
        </tr>
        <tr>
          <td>CARAMEL FRAPPUCCINO (WITHOUT CREAM)</td>
          <td className="ft-Wt-700"> 320 </td>
          <td className="ft-Wt-700">354ML</td>
        </tr>
        <tr>
          <td>COLD COFFEE</td>
          <td className="ft-Wt-700"> 310 </td>
          <td className="ft-Wt-700">354ML</td>
        </tr>
        <tr>
          <td>MOCHA FRAPPUCCINO (WITHOUT CREAM)</td>
          <td className="ft-Wt-700"> 350 </td>
          <td className="ft-Wt-700">354ML</td>
        </tr>
        <tr>
          <td>HIBISCUS ICED TEA</td>
          <td className="ft-Wt-700"> 310 </td>
          <td className="ft-Wt-700">354ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>FOOD</td>
        </tr>
        <tr>
          <td>HAWAIIAN VEG CLUB SANDWICH</td>
          <td className="ft-Wt-700"> 390 </td>
          <td className="ft-Wt-700">185GM</td>
        </tr>
        <tr>
          <td>CHILLI PANEER IN HERBED BUN / CHILLI PANEER SANDWICH </td>
          <td className="ft-Wt-700"> 280 </td>
          <td className="ft-Wt-700">185GM</td>
        </tr>
        <tr>
          <td>SPINACH N CORN SANDWICH</td>
          <td className="ft-Wt-700"> 270 </td>
          <td className="ft-Wt-700">140GM</td>
        </tr>
        <tr>
          <td>CHOCOLATE TRUFFLE CAKE</td>
          <td className="ft-Wt-700"> 390 </td>
          <td className="ft-Wt-700">100GM</td>
        </tr>
        <tr>
          <td>ALMOND BUTTERSCOTCH COOKIE</td>
          <td className="ft-Wt-700"> 300 </td>
          <td className="ft-Wt-700">85GM</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>RTD\RTE</td>
        </tr>
        <tr>
          <td>ALMONDS</td>
          <td className="ft-Wt-700"> 180 </td>
          <td className="ft-Wt-700">50GM</td>
        </tr>
        <tr>
          <td>CASHEWS</td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">50GM</td>
        </tr>
        <tr>
          <td>ABC JUICE </td>
          <td className="ft-Wt-700"> 280 </td>
          <td className="ft-Wt-700">250ML</td>
        </tr>
        <tr>
          <td>ORANGE JUICE </td>
          <td className="ft-Wt-700"> 280 </td>
          <td className="ft-Wt-700">250ML</td>
        </tr>
      </table>
    );
  },
  cafe2: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>HIGHNESS PANINI</td>
        </tr>
        <tr className="head">
          <td>Menu</td>
          <td>MRP</td>
          <td>GM / ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>SNACKS & BAKERY</td>
        </tr>
        <tr>
          <td>MEXICAN (JAIN/REGULAR)</td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">200 GMS</td>
        </tr>
        <tr>
          <td>PANEER TIKKA</td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">200 GMS</td>
        </tr>
        <tr>
          <td>CHEESE MELT PANINI (JAIN/REGULAR)</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">220 GMS</td>
        </tr>
        <tr>
          <td>CHEESE MAYO GARLIC BREAD</td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">120 GMS </td>
        </tr>
        <tr>
          <td>VEG GRILLED SANDWICH (JAIN/REGULAR)</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">220 GMS</td>
        </tr>
        <tr>
          <td>MELTED SANDWICH (JAIN/REGULAR)</td>
          <td className="ft-Wt-700"> 300 </td>
          <td className="ft-Wt-700">250 GMS</td>
        </tr>
        <tr>
          <td>CHEESE CHILLI SANDWICH </td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">220 GMS</td>
        </tr>
        <tr>
          <td>MASKA BUN</td>
          <td className="ft-Wt-700"> 80 </td>
          <td className="ft-Wt-700">50 GMS</td>
        </tr>
        <tr>
          <td>JAM BUN</td>
          <td className="ft-Wt-700"> 80 </td>
          <td className="ft-Wt-700">50 GMS</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>PIZZA</td>
        </tr>
        <tr>
          <td>MARGARITA PIZZA (JAIN/REGULAR)</td>
          <td className="ft-Wt-700"> 280 </td>
          <td className="ft-Wt-700">140 GMS </td>
        </tr>
        <tr>
          <td>PEPPY PANEER PIZZA (JAIN/REGULAR)</td>
          <td className="ft-Wt-700"> 300 </td>
          <td className="ft-Wt-700">170 GMS </td>
        </tr>
        <tr>
          <td>FARMHOUSE PIZZA (JAIN/REGULAR)</td>
          <td className="ft-Wt-700"> 300 </td>
          <td className="ft-Wt-700">170 GMS </td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>ROLL</td>
        </tr>
        <tr>
          <td>PANEER TIKKA ROLL</td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">150 GMS</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>HOT BEVERAGES</td>
        </tr>
        <tr>
          <td>REGULAR ADRAK TEA</td>
          <td className="ft-Wt-700"> 50 </td>
          <td className="ft-Wt-700">100 ML</td>
        </tr>
        <tr>
          <td>MASALA TEA</td>
          <td className="ft-Wt-700"> 70 </td>
          <td className="ft-Wt-700">100 ML</td>
        </tr>
        <tr>
          <td>BLACK TEA</td>
          <td className="ft-Wt-700"> 50 </td>
          <td className="ft-Wt-700">100 ML</td>
        </tr>
        <tr>
          <td>LEMON TEA</td>
          <td className="ft-Wt-700"> 70 </td>
          <td className="ft-Wt-700">150 ML</td>
        </tr>
        <tr>
          <td>GREEN TEA</td>
          <td className="ft-Wt-700"> 70 </td>
          <td className="ft-Wt-700">150 ML</td>
        </tr>
        <tr>
          <td>FILTER COFFEE</td>
          <td className="ft-Wt-700"> 80 </td>
          <td className="ft-Wt-700">100ML</td>
        </tr>
        <tr>
          <td>CAFE AMERICANO</td>
          <td className="ft-Wt-700"> 150 </td>
          <td className="ft-Wt-700">200ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>COLD BEVERAGES</td>
        </tr>
        <tr>
          <td>SIGNATURE MOCHA COLD COFFEE </td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">300ML</td>
        </tr>
        <tr>
          <td>SIGNATURE COLD COFFEE </td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">300ML</td>
        </tr>
        <tr>
          <td>SIGNATURE ICE AMERICANO</td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">300ML</td>
        </tr>
        <tr>
          <td>LEMON ICED TEA</td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">300ML</td>
        </tr>
        <tr>
          <td>PEACH ICED TEA</td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">300ML</td>
        </tr>
      </table>
    );
  },
  cafe3: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>CHAI POINT</td>
        </tr>
        <tr className="head">
          <td>Menu</td>
          <td>MRP</td>
          <td>GM / ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>HOT BEVERAGES</td>
        </tr>
        <tr>
          <td>CAPPUCINNO</td>
          <td className="ft-Wt-700"> 180 </td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>CAFÉ LATTE</td>
          <td className="ft-Wt-700"> 180 </td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>CAFÉ MOCHA</td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>AMERICANO</td>
          <td className="ft-Wt-700"> 180 </td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>HOT CHOCOLATE</td>
          <td className="ft-Wt-700"> 160 </td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>MASALA TEA</td>
          <td className="ft-Wt-700"> 60 </td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>HOT FILTER COFFEE </td>
          <td className="ft-Wt-700"> 70 </td>
          <td className="ft-Wt-700">130</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>COLD BEVERAGES</td>
        </tr>
        <tr>
          <td>COLD COFFEE</td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">300</td>
        </tr>
        <tr>
          <td>ICE CHOCOLATE </td>
          <td className="ft-Wt-700"> 150 </td>
          <td className="ft-Wt-700">300</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>SANDWICH</td>
        </tr>
        <tr>
          <td>VEG GRILLED SANDWICH </td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>VEG CHEESE GRILLED S/W (JAIN /REGULAR)</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>MELTED CHILI CHEESE TOAST S/W</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>MAXICAN PANEER GRILL S/W</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">170</td>
        </tr>
        <tr>
          <td>PANEER TIKKA WRAP</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">170</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>PIZZA</td>
        </tr>
        <tr>
          <td>DELUX VEGGIE PIZZA (JAIN / REGULAR)</td>
          <td className="ft-Wt-700"> 270 </td>
          <td className="ft-Wt-700">180</td>
        </tr>
        <tr>
          <td>CLASSIC MARGRITA PIZZA (JAIN / REGULAR)</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">180</td>
        </tr>
        <tr>
          <td>PEPPY PANEER CHEESE BURST PIZZA</td>
          <td className="ft-Wt-700"> 300 </td>
          <td className="ft-Wt-700">200</td>
        </tr>
      </table>
    );
  },
  cafe4: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>HATTI KAPPI</td>
        </tr>
        <tr className="head">
          <td>Menu</td>
          <td>MRP</td>
          <td>GM / ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>KAAPI</td>
        </tr>
        <tr>
          <td>KAAPI</td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">180 ML</td>
        </tr>
        <tr>
          <td>KAAPI LARGE </td>
          <td className="ft-Wt-700"> 120 </td>
          <td className="ft-Wt-700">250 ML</td>
        </tr>
        <tr>
          <td>BELLA KAAPI</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">180 ML</td>
        </tr>
        <tr>
          <td>BELLA KAAPI LARGE </td>
          <td className="ft-Wt-700"> 130 </td>
          <td className="ft-Wt-700">250 ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>CHAIS</td>
        </tr>
        <tr>
          <td>CHAI</td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">180 ML</td>
        </tr>
        <tr>
          <td>CHAI</td>
          <td className="ft-Wt-700"> 120 </td>
          <td className="ft-Wt-700">250 ML</td>
        </tr>
        <tr>
          <td>GINGER CHAI</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">180 ML</td>
        </tr>
        <tr>
          <td>GINGER CHAI</td>
          <td className="ft-Wt-700"> 130 </td>
          <td className="ft-Wt-700">250 ML</td>
        </tr>
        <tr>
          <td>LEMON CHAI</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">180 ML</td>
        </tr>
        <tr>
          <td>LEMON CHAI</td>
          <td className="ft-Wt-700"> 130 </td>
          <td className="ft-Wt-700">250 ML</td>
        </tr>
        <tr>
          <td>GREEN CHAI</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">180 ML</td>
        </tr>
        <tr>
          <td>GREEN CHAI</td>
          <td className="ft-Wt-700"> 130 </td>
          <td className="ft-Wt-700">250 ML</td>
        </tr>
        <tr>
          <td>BADAM MILK</td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">180 ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>COLD BEVERAGES</td>
        </tr>
        <tr>
          <td>COLD COFFEE</td>
          <td className="ft-Wt-700"> 160 </td>
          <td className="ft-Wt-700">300 ML</td>
        </tr>
        <tr>
          <td>COLD BADAM MILK</td>
          <td className="ft-Wt-700"> 160 </td>
          <td className="ft-Wt-700">300 ML</td>
        </tr>
        <tr>
          <td>COLD LEMON CHAI</td>
          <td className="ft-Wt-700"> 160 </td>
          <td className="ft-Wt-700">300 ML</td>
        </tr>
        <tr>
          <td>CHOCOLATE COLD COFFEE</td>
          <td className="ft-Wt-700"> 180 </td>
          <td className="ft-Wt-700">300 ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>FOOD</td>
        </tr>
        <tr>
          <td>ALOO MASALA SAMOSA (2PCS)</td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">90 GMS</td>
        </tr>
        <tr>
          <td>JAIN SAMOSA (2 PCS)</td>
          <td className="ft-Wt-700"> 130 </td>
          <td className="ft-Wt-700">150 GMS</td>
        </tr>
        <tr>
          <td>SABUDANA VADA (2 PCS)</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">100GMS</td>
        </tr>
        <tr>
          <td>PANEER WRAP</td>
          <td className="ft-Wt-700"> 170 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>CHOCOLATE DOUGHNUTS (1 PC)</td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">110GMS</td>
        </tr>
        <tr>
          <td>MIX VEG WRAP</td>
          <td className="ft-Wt-700"> 150 </td>
          <td className="ft-Wt-700">200 GMS</td>
        </tr>
        <tr>
          <td>ACHARI TOFU WRAP</td>
          <td className="ft-Wt-700"> 170 </td>
          <td className="ft-Wt-700">200 GMS</td>
        </tr>
        <tr>
          <td>SPINACH & CORN SANDWICH</td>
          <td className="ft-Wt-700"> 170 </td>
          <td className="ft-Wt-700">150 GMS</td>
        </tr>
        <tr>
          <td>PEPPER MUSHROOM </td>
          <td className="ft-Wt-700"> 170 </td>
          <td className="ft-Wt-700">200 GMS</td>
        </tr>
        <tr>
          <td>TANDOORI TIKKA SUB SANDWICH</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">220GMS</td>
        </tr>
        <tr>
          <td>KAAPI BROWNIE (1 PC)</td>
          <td className="ft-Wt-700"> 120 </td>
          <td className="ft-Wt-700">100 GMS</td>
        </tr>
        <tr>
          <td>KOREAN BUNS (1PC)</td>
          <td className="ft-Wt-700"> 120 </td>
          <td className="ft-Wt-700">90 GMS</td>
        </tr>
        <tr>
          <td>BANANA CAKE (1 PC)</td>
          <td className="ft-Wt-700"> 120 </td>
          <td className="ft-Wt-700">125 GMS</td>
        </tr>
        <tr>
          <td>BLACK FOREST CAKE</td>
          <td className="ft-Wt-700"> 170 </td>
          <td className="ft-Wt-700">110GMS</td>
        </tr>
        <tr>
          <td>MUFFIN CHOCOLATE </td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">100GMS</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>6 SECONDS MERCHANDISE</td>
        </tr>
        <tr>
          <td>6 SECONDS FILTER COFFEE DECOCTION (PACK OF 1)</td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>6 SEONDS HATTI KASHAYA (PACK OF 1)</td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>6 SECONDS HATTI GOLDEN LATTE (PACK OF 1)</td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>KAAPI POWDER TUBE (250 GM)</td>
        </tr>
        <tr>
          <td>CHICORY BLEND (80:20) </td>
          <td className="ft-Wt-700"> 150 </td>
          <td className="ft-Wt-700">250GMS</td>
        </tr>
        <tr>
          <td>MYSORE KAAPI</td>
          <td className="ft-Wt-700"> 190 </td>
          <td className="ft-Wt-700">250GMS</td>
        </tr>
        <tr>
          <td>PURE KAAPI</td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">250GMS</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>KAAPI POWDER TIN (200 GM)</td>
        </tr>
        <tr>
          <td>MALNAD DAYS</td>
          <td className="ft-Wt-700"> 500 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>SUTRA</td>
          <td className="ft-Wt-700"> 500 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>MONSOON MALABAR</td>
          <td className="ft-Wt-700"> 500 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>MYSORE NUGGETS</td>
          <td className="ft-Wt-700"> 500 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>JAGGERY POWDER</td>
          <td className="ft-Wt-700"> 80 </td>
          <td className="ft-Wt-700"> 250 GM</td>
        </tr>
        <tr>
          <td>INDIANO (ROASTED BEANS)</td>
          <td className="ft-Wt-700"> 500 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>PACKED SNACKS</td>
        </tr>
        <tr>
          <td>KODUBALE (SAVOURIES)</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>BOMBAY MIXTURE (SAVOURIES)</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>COATED PEANUTS (SAVOURIES)</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>MOONG DAL (SAVOURIES)</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>KHARA BOONDHI (SAVOURIES)</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>MADDUR VADA (PACK OF 5)</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">250GMS</td>
        </tr>
        <tr>
          <td>OATMEAL COOKIES (PACK OF 6)</td>
          <td className="ft-Wt-700"> 140 </td>
          <td className="ft-Wt-700">240GMS</td>
        </tr>
      </table>
    );
  },
  cafe5: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>BARISTA</td>
        </tr>
        <tr className="head">
          <td>Menu</td>
          <td>MRP</td>
          <td>GM / ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>BEVERAGES</td>
        </tr>
        <tr>
          <td>ESPRESSO ITALIANO </td>
          <td className="ft-Wt-700"> 160 </td>
          <td className="ft-Wt-700">200ml</td>
        </tr>
        <tr>
          <td>ESPRESSO ITALIANO </td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">300ml</td>
        </tr>
        <tr>
          <td>CAFÉ AMERICANO </td>
          <td className="ft-Wt-700"> 180 </td>
          <td className="ft-Wt-700">200ml</td>
        </tr>
        <tr>
          <td>CAFÉ AMERICANO </td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">300ml</td>
        </tr>
        <tr>
          <td>CAFÉ LATTE </td>
          <td className="ft-Wt-700"> 220 </td>
          <td className="ft-Wt-700">200ml</td>
        </tr>
        <tr>
          <td>CAFÉ LATTE </td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">300ml</td>
        </tr>
        <tr>
          <td>CAPPUCCINO </td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">200ml</td>
        </tr>
        <tr>
          <td>CAPPUCCINO </td>
          <td className="ft-Wt-700"> 230 </td>
          <td className="ft-Wt-700">300ml</td>
        </tr>
        <tr>
          <td>CAFÉ MOCHA </td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">200ml</td>
        </tr>
        <tr>
          <td>CAFÉ MOCHA </td>
          <td className="ft-Wt-700"> 300 </td>
          <td className="ft-Wt-700">300ml</td>
        </tr>
        <tr>
          <td>HOT CHOCOLATE </td>
          <td className="ft-Wt-700"> 260 </td>
          <td className="ft-Wt-700">200ml</td>
        </tr>
        <tr>
          <td>HOT CHOCOLATE </td>
          <td className="ft-Wt-700"> 300 </td>
          <td className="ft-Wt-700">300ml</td>
        </tr>
        <tr>
          <td>FLAT WHITE </td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">200ml</td>
        </tr>
        <tr>
          <td>FLAT WHITE </td>
          <td className="ft-Wt-700"> 280 </td>
          <td className="ft-Wt-700">300ml</td>
        </tr>
        <tr>
          <td>BARISTA BLAST</td>
          <td className="ft-Wt-700"> 360 </td>
          <td className="ft-Wt-700">360ml</td>
        </tr>
        <tr>
          <td>BARISTA BLAST</td>
          <td className="ft-Wt-700"> 400 </td>
          <td className="ft-Wt-700">420ml</td>
        </tr>
        <tr>
          <td>BARISTA FRAPPE</td>
          <td className="ft-Wt-700"> 300 </td>
          <td className="ft-Wt-700">360ml</td>
        </tr>
        <tr>
          <td>BARISTA FRAPPE</td>
          <td className="ft-Wt-700"> 350 </td>
          <td className="ft-Wt-700">420ml</td>
        </tr>
        <tr>
          <td>CHOCOLATE SMOOTHIE</td>
          <td className="ft-Wt-700"> 300 </td>
          <td className="ft-Wt-700">360ml</td>
        </tr>
        <tr>
          <td>CHOCOLATE SMOOTHIE</td>
          <td className="ft-Wt-700"> 350 </td>
          <td className="ft-Wt-700">420ml</td>
        </tr>
        <tr>
          <td>GINGER HONEY</td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">200ml</td>
        </tr>
        <tr>
          <td>BARISTA</td>
          <td className="ft-Wt-700"> 220 </td>
          <td className="ft-Wt-700">360ml</td>
        </tr>
        <tr>
          <td>BARISTA</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">420ml</td>
        </tr>
        <tr>
          <td>ICED AMERICANO</td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">360ml</td>
        </tr>
        <tr>
          <td>ICED AMERICANO</td>
          <td className="ft-Wt-700"> 230 </td>
          <td className="ft-Wt-700">420ml</td>
        </tr>
        <tr>
          <td>ICED CAFÉ MOCHA </td>
          <td className="ft-Wt-700"> 280 </td>
          <td className="ft-Wt-700">360ml</td>
        </tr>
        <tr>
          <td>ICED CAFÉ MOCHA </td>
          <td className="ft-Wt-700"> 300 </td>
          <td className="ft-Wt-700">420ml</td>
        </tr>
        <tr>
          <td>ICED LATTE</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">360ml</td>
        </tr>
        <tr>
          <td>ICED LATTE</td>
          <td className="ft-Wt-700"> 280 </td>
          <td className="ft-Wt-700">420ml</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>FOOD</td>
        </tr>
        <tr>
          <td>PANEER TIKKA SANDWICH </td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">200gm</td>
        </tr>
        <tr>
          <td>SPINACH & CORN SANDWICH</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">200gm</td>
        </tr>
        <tr>
          <td>DARK TEMPTATION</td>
          <td className="ft-Wt-700"> 350 </td>
          <td className="ft-Wt-700">220gm</td>
        </tr>
        <tr>
          <td>CHOCOLATE EXCESS</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">125gm</td>
        </tr>
        <tr>
          <td>ACHARI PANEER ROLL </td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">200ml</td>
        </tr>
        <tr>
          <td>BLUEBERRY MUFFIN</td>
          <td className="ft-Wt-700"> 220 </td>
          <td className="ft-Wt-700">120gm</td>
        </tr>
        <tr>
          <td>CHOCO CHIP MUFFIN</td>
          <td className="ft-Wt-700"> 220 </td>
          <td className="ft-Wt-700">120gm</td>
        </tr>
        <tr>
          <td> ALMOND MUFFIN</td>
          <td className="ft-Wt-700"> 220 </td>
          <td className="ft-Wt-700">120gm</td>
        </tr>
        <tr>
          <td>BROWNIE FONDENTE</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">100gm</td>
        </tr>
        <tr>
          <td>MARBLE CAKE </td>
          <td className="ft-Wt-700"> 150 </td>
          <td className="ft-Wt-700">100gm</td>
        </tr>
        <tr>
          <td>WICKED BROWNIE</td>
          <td className="ft-Wt-700"> 350 </td>
          <td className="ft-Wt-700">195gm</td>
        </tr>
        <tr>
          <td>DOUBLE CHOCOLATE CHIP COOKIE</td>
          <td className="ft-Wt-700"> 130 </td>
          <td className="ft-Wt-700">50gm</td>
        </tr>
        <tr>
          <td>CHOCOLATE EXCESS -SLICE</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">125gm</td>
        </tr>
        <tr>
          <td>VEGETARIAN PUFF</td>
          <td className="ft-Wt-700"> 150 </td>
          <td className="ft-Wt-700">110gm</td>
        </tr>
        <tr>
          <td>THREE PEPPER CHEESE TOASTY</td>
          <td className="ft-Wt-700"> 160 </td>
          <td className="ft-Wt-700">120gm</td>
        </tr>
        <tr>
          <td>OATMEAL RAISIN COOKIE</td>
          <td className="ft-Wt-700"> 130 </td>
          <td className="ft-Wt-700">50gm</td>
        </tr>
      </table>
    );
  },
  cafe6: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>HATTI KAPPI</td>
        </tr>
        <tr className="head">
          <td>Menu</td>
          <td>MRP</td>
          <td>GM / ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>KAAPI</td>
        </tr>
        <tr>
          <td>KAAPI</td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">180 ML</td>
        </tr>
        <tr>
          <td>KAAPI LARGE </td>
          <td className="ft-Wt-700"> 120 </td>
          <td className="ft-Wt-700">250 ML</td>
        </tr>
        <tr>
          <td>BELLA KAAPI</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">180 ML</td>
        </tr>
        <tr>
          <td>BELLA KAAPI LARGE </td>
          <td className="ft-Wt-700"> 130 </td>
          <td className="ft-Wt-700">250 ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>CHAIS</td>
        </tr>
        <tr>
          <td>CHAI</td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">180 ML</td>
        </tr>
        <tr>
          <td>CHAI</td>
          <td className="ft-Wt-700"> 120 </td>
          <td className="ft-Wt-700">250 ML</td>
        </tr>
        <tr>
          <td>GINGER CHAI</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">180 ML</td>
        </tr>
        <tr>
          <td>GINGER CHAI</td>
          <td className="ft-Wt-700"> 130 </td>
          <td className="ft-Wt-700">250 ML</td>
        </tr>
        <tr>
          <td>LEMON CHAI</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">180 ML</td>
        </tr>
        <tr>
          <td>LEMON CHAI</td>
          <td className="ft-Wt-700"> 130 </td>
          <td className="ft-Wt-700">250 ML</td>
        </tr>
        <tr>
          <td>GREEN CHAI</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">180 ML</td>
        </tr>
        <tr>
          <td>GREEN CHAI</td>
          <td className="ft-Wt-700"> 130 </td>
          <td className="ft-Wt-700">250 ML</td>
        </tr>
        <tr>
          <td>BADAM MILK</td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">180 ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>COLD BEVERAGES</td>
        </tr>
        <tr>
          <td>COLD COFFEE</td>
          <td className="ft-Wt-700"> 160 </td>
          <td className="ft-Wt-700">300 ML</td>
        </tr>
        <tr>
          <td>COLD BADAM MILK</td>
          <td className="ft-Wt-700"> 160 </td>
          <td className="ft-Wt-700">300 ML</td>
        </tr>
        <tr>
          <td>COLD LEMON CHAI</td>
          <td className="ft-Wt-700"> 160 </td>
          <td className="ft-Wt-700">300 ML</td>
        </tr>
        <tr>
          <td>CHOCOLATE COLD COFFEE</td>
          <td className="ft-Wt-700"> 180 </td>
          <td className="ft-Wt-700">300 ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>FOOD</td>
        </tr>
        <tr>
          <td>ALOO MASALA SAMOSA (2PCS)</td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">90 GMS</td>
        </tr>
        <tr>
          <td>JAIN SAMOSA (2 PCS)</td>
          <td className="ft-Wt-700"> 130 </td>
          <td className="ft-Wt-700">150 GMS</td>
        </tr>
        <tr>
          <td>SABUDANA VADA (2 PCS)</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">100GMS</td>
        </tr>
        <tr>
          <td>PANEER WRAP</td>
          <td className="ft-Wt-700"> 170 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>CHOCOLATE DOUGHNUTS (1 PC)</td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">110GMS</td>
        </tr>
        <tr>
          <td>MIX VEG WRAP</td>
          <td className="ft-Wt-700"> 150 </td>
          <td className="ft-Wt-700">200 GMS</td>
        </tr>
        <tr>
          <td>ACHARI TOFU WRAP</td>
          <td className="ft-Wt-700"> 170 </td>
          <td className="ft-Wt-700">200 GMS</td>
        </tr>
        <tr>
          <td>SPINACH & CORN SANDWICH</td>
          <td className="ft-Wt-700"> 170 </td>
          <td className="ft-Wt-700">150 GMS</td>
        </tr>
        <tr>
          <td>PEPPER MUSHROOM </td>
          <td className="ft-Wt-700"> 170 </td>
          <td className="ft-Wt-700">200 GMS</td>
        </tr>
        <tr>
          <td>TANDOORI TIKKA SUB SANDWICH</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">220GMS</td>
        </tr>
        <tr>
          <td>KAAPI BROWNIE (1 PC)</td>
          <td className="ft-Wt-700"> 120 </td>
          <td className="ft-Wt-700">100 GMS</td>
        </tr>
        <tr>
          <td>KOREAN BUNS (1PC)</td>
          <td className="ft-Wt-700"> 120 </td>
          <td className="ft-Wt-700">90 GMS</td>
        </tr>
        <tr>
          <td>BANANA CAKE (1 PC)</td>
          <td className="ft-Wt-700"> 120 </td>
          <td className="ft-Wt-700">125 GMS</td>
        </tr>
        <tr>
          <td>BLACK FOREST CAKE</td>
          <td className="ft-Wt-700"> 170 </td>
          <td className="ft-Wt-700">110GMS</td>
        </tr>
        <tr>
          <td>MUFFIN CHOCOLATE </td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">100GMS</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>6 SECONDS MERCHANDISE</td>
        </tr>
        <tr>
          <td>6 SECONDS FILTER COFFEE DECOCTION (PACK OF 1)</td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>6 SEONDS HATTI KASHAYA (PACK OF 1)</td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>6 SECONDS HATTI GOLDEN LATTE (PACK OF 1)</td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>KAAPI POWDER TUBE (250 GM)</td>
        </tr>
        <tr>
          <td>CHICORY BLEND (80:20) </td>
          <td className="ft-Wt-700"> 150 </td>
          <td className="ft-Wt-700">250GMS</td>
        </tr>
        <tr>
          <td>MYSORE KAAPI</td>
          <td className="ft-Wt-700"> 190 </td>
          <td className="ft-Wt-700">250GMS</td>
        </tr>
        <tr>
          <td>PURE KAAPI</td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">250GMS</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>KAAPI POWDER TIN (200 GM)</td>
        </tr>
        <tr>
          <td>MALNAD DAYS</td>
          <td className="ft-Wt-700"> 500 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>SUTRA</td>
          <td className="ft-Wt-700"> 500 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>MONSOON MALABAR</td>
          <td className="ft-Wt-700"> 500 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>MYSORE NUGGETS</td>
          <td className="ft-Wt-700"> 500 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>JAGGERY POWDER</td>
          <td className="ft-Wt-700"> 80 </td>
          <td className="ft-Wt-700"> 250 GM</td>
        </tr>
        <tr>
          <td>INDIANO (ROASTED BEANS)</td>
          <td className="ft-Wt-700"> 500 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>PACKED SNACKS</td>
        </tr>
        <tr>
          <td>KODUBALE (SAVOURIES)</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>BOMBAY MIXTURE (SAVOURIES)</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>COATED PEANUTS (SAVOURIES)</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>MOONG DAL (SAVOURIES)</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>KHARA BOONDHI (SAVOURIES)</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>MADDUR VADA (PACK OF 5)</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">250GMS</td>
        </tr>
        <tr>
          <td>OATMEAL COOKIES (PACK OF 6)</td>
          <td className="ft-Wt-700"> 140 </td>
          <td className="ft-Wt-700">240GMS</td>
        </tr>
      </table>
    );
  },
  cafe7: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>STARBUCKS</td>
        </tr>
        <tr className="head">
          <td>Menu</td>
          <td>MRP</td>
          <td>GM / ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>HOT COFFEE</td>
        </tr>
        <tr>
          <td>FLAT WHITE</td>
          <td className="ft-Wt-700"> 280 </td>
          <td className="ft-Wt-700">237ML</td>
        </tr>
        <tr>
          <td>CAFÉ MOCHA (WITHOUT CREAM)</td>
          <td className="ft-Wt-700"> 310 </td>
          <td className="ft-Wt-700">237ML</td>
        </tr>
        <tr>
          <td>CAFÉ LATTE</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">237ML</td>
        </tr>
        <tr>
          <td>CAPPUCCINO </td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">237ML</td>
        </tr>
        <tr>
          <td>CAFÉ AMERICANO</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">237ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>HOT TEA</td>
        </tr>
        <tr>
          <td>EMPEROR'S CLOUD MIST (GREEN TEA)</td>
          <td className="ft-Wt-700"> 310 </td>
          <td className="ft-Wt-700">237ML</td>
        </tr>
        <tr>
          <td>CHAI TEA LATTE</td>
          <td className="ft-Wt-700"> 280 </td>
          <td className="ft-Wt-700">237ML</td>
        </tr>
        <tr>
          <td>ENGLISH BREAKFAST TEA (BLACK TEA)</td>
          <td className="ft-Wt-700"> 300 </td>
          <td className="ft-Wt-700">237ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>FRAPPUCCINO\COLD COFFEE</td>
        </tr>
        <tr>
          <td>JAVACHIP FRAPPUCCINO (WITHOUT CREAM)</td>
          <td className="ft-Wt-700"> 330 </td>
          <td className="ft-Wt-700">354ML</td>
        </tr>
        <tr>
          <td>CARAMEL FRAPPUCCINO (WITHOUT CREAM)</td>
          <td className="ft-Wt-700"> 320 </td>
          <td className="ft-Wt-700">354ML</td>
        </tr>
        <tr>
          <td>COLD COFFEE</td>
          <td className="ft-Wt-700"> 310 </td>
          <td className="ft-Wt-700">354ML</td>
        </tr>
        <tr>
          <td>MOCHA FRAPPUCCINO (WITHOUT CREAM)</td>
          <td className="ft-Wt-700"> 350 </td>
          <td className="ft-Wt-700">354ML</td>
        </tr>
        <tr>
          <td>HIBISCUS ICED TEA</td>
          <td className="ft-Wt-700"> 310 </td>
          <td className="ft-Wt-700">354ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>FOOD</td>
        </tr>
        <tr>
          <td>HAWAIIAN VEG CLUB SANDWICH</td>
          <td className="ft-Wt-700"> 390 </td>
          <td className="ft-Wt-700">185GM</td>
        </tr>
        <tr>
          <td>CHILLI PANEER IN HERBED BUN / CHILLI PANEER SANDWICH </td>
          <td className="ft-Wt-700"> 280 </td>
          <td className="ft-Wt-700">185GM</td>
        </tr>
        <tr>
          <td>SPINACH N CORN SANDWICH</td>
          <td className="ft-Wt-700"> 270 </td>
          <td className="ft-Wt-700">140GM</td>
        </tr>
        <tr>
          <td>CHOCOLATE TRUFFLE CAKE</td>
          <td className="ft-Wt-700"> 390 </td>
          <td className="ft-Wt-700">100GM</td>
        </tr>
        <tr>
          <td>ALMOND BUTTERSCOTCH COOKIE</td>
          <td className="ft-Wt-700"> 300 </td>
          <td className="ft-Wt-700">85GM</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>RTD\RTE</td>
        </tr>
        <tr>
          <td>ALMONDS</td>
          <td className="ft-Wt-700"> 180 </td>
          <td className="ft-Wt-700">50GM</td>
        </tr>
        <tr>
          <td>CASHEWS</td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">50GM</td>
        </tr>
        <tr>
          <td>ABC JUICE </td>
          <td className="ft-Wt-700"> 280 </td>
          <td className="ft-Wt-700">250ML</td>
        </tr>
        <tr>
          <td>ORANGE JUICE </td>
          <td className="ft-Wt-700"> 280 </td>
          <td className="ft-Wt-700">250ML</td>
        </tr>
      </table>
    );
  },
  cafe8: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>HATTI KAPPI</td>
        </tr>
        <tr className="head">
          <td>Menu</td>
          <td>MRP</td>
          <td>GM / ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>KAAPI</td>
        </tr>
        <tr>
          <td>KAAPI</td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">180 ML</td>
        </tr>
        <tr>
          <td>KAAPI LARGE </td>
          <td className="ft-Wt-700"> 120 </td>
          <td className="ft-Wt-700">250 ML</td>
        </tr>
        <tr>
          <td>BELLA KAAPI</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">180 ML</td>
        </tr>
        <tr>
          <td>BELLA KAAPI LARGE </td>
          <td className="ft-Wt-700"> 130 </td>
          <td className="ft-Wt-700">250 ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>CHAIS</td>
        </tr>
        <tr>
          <td>CHAI</td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">180 ML</td>
        </tr>
        <tr>
          <td>CHAI</td>
          <td className="ft-Wt-700"> 120 </td>
          <td className="ft-Wt-700">250 ML</td>
        </tr>
        <tr>
          <td>GINGER CHAI</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">180 ML</td>
        </tr>
        <tr>
          <td>GINGER CHAI</td>
          <td className="ft-Wt-700"> 130 </td>
          <td className="ft-Wt-700">250 ML</td>
        </tr>
        <tr>
          <td>LEMON CHAI</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">180 ML</td>
        </tr>
        <tr>
          <td>LEMON CHAI</td>
          <td className="ft-Wt-700"> 130 </td>
          <td className="ft-Wt-700">250 ML</td>
        </tr>
        <tr>
          <td>GREEN CHAI</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">180 ML</td>
        </tr>
        <tr>
          <td>GREEN CHAI</td>
          <td className="ft-Wt-700"> 130 </td>
          <td className="ft-Wt-700">250 ML</td>
        </tr>
        <tr>
          <td>BADAM MILK</td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">180 ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>COLD BEVERAGES</td>
        </tr>
        <tr>
          <td>COLD COFFEE</td>
          <td className="ft-Wt-700"> 160 </td>
          <td className="ft-Wt-700">300 ML</td>
        </tr>
        <tr>
          <td>COLD BADAM MILK</td>
          <td className="ft-Wt-700"> 160 </td>
          <td className="ft-Wt-700">300 ML</td>
        </tr>
        <tr>
          <td>COLD LEMON CHAI</td>
          <td className="ft-Wt-700"> 160 </td>
          <td className="ft-Wt-700">300 ML</td>
        </tr>
        <tr>
          <td>CHOCOLATE COLD COFFEE</td>
          <td className="ft-Wt-700"> 180 </td>
          <td className="ft-Wt-700">300 ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>FOOD</td>
        </tr>
        <tr>
          <td>ALOO MASALA SAMOSA (2PCS)</td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">90 GMS</td>
        </tr>
        <tr>
          <td>JAIN SAMOSA (2 PCS)</td>
          <td className="ft-Wt-700"> 130 </td>
          <td className="ft-Wt-700">150 GMS</td>
        </tr>
        <tr>
          <td>SABUDANA VADA (2 PCS)</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">100GMS</td>
        </tr>
        <tr>
          <td>PANEER WRAP</td>
          <td className="ft-Wt-700"> 170 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>CHOCOLATE DOUGHNUTS (1 PC)</td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">110GMS</td>
        </tr>
        <tr>
          <td>MIX VEG WRAP</td>
          <td className="ft-Wt-700"> 150 </td>
          <td className="ft-Wt-700">200 GMS</td>
        </tr>
        <tr>
          <td>ACHARI TOFU WRAP</td>
          <td className="ft-Wt-700"> 170 </td>
          <td className="ft-Wt-700">200 GMS</td>
        </tr>
        <tr>
          <td>SPINACH & CORN SANDWICH</td>
          <td className="ft-Wt-700"> 170 </td>
          <td className="ft-Wt-700">150 GMS</td>
        </tr>
        <tr>
          <td>PEPPER MUSHROOM </td>
          <td className="ft-Wt-700"> 170 </td>
          <td className="ft-Wt-700">200 GMS</td>
        </tr>
        <tr>
          <td>TANDOORI TIKKA SUB SANDWICH</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">220GMS</td>
        </tr>
        <tr>
          <td>KAAPI BROWNIE (1 PC)</td>
          <td className="ft-Wt-700"> 120 </td>
          <td className="ft-Wt-700">100 GMS</td>
        </tr>
        <tr>
          <td>KOREAN BUNS (1PC)</td>
          <td className="ft-Wt-700"> 120 </td>
          <td className="ft-Wt-700">90 GMS</td>
        </tr>
        <tr>
          <td>BANANA CAKE (1 PC)</td>
          <td className="ft-Wt-700"> 120 </td>
          <td className="ft-Wt-700">125 GMS</td>
        </tr>
        <tr>
          <td>BLACK FOREST CAKE</td>
          <td className="ft-Wt-700"> 170 </td>
          <td className="ft-Wt-700">110GMS</td>
        </tr>
        <tr>
          <td>MUFFIN CHOCOLATE </td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">100GMS</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>6 SECONDS MERCHANDISE</td>
        </tr>
        <tr>
          <td>6 SECONDS FILTER COFFEE DECOCTION (PACK OF 1)</td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>6 SEONDS HATTI KASHAYA (PACK OF 1)</td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>6 SECONDS HATTI GOLDEN LATTE (PACK OF 1)</td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>KAAPI POWDER TUBE (250 GM)</td>
        </tr>
        <tr>
          <td>CHICORY BLEND (80:20) </td>
          <td className="ft-Wt-700"> 150 </td>
          <td className="ft-Wt-700">250GMS</td>
        </tr>
        <tr>
          <td>MYSORE KAAPI</td>
          <td className="ft-Wt-700"> 190 </td>
          <td className="ft-Wt-700">250GMS</td>
        </tr>
        <tr>
          <td>PURE KAAPI</td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">250GMS</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>KAAPI POWDER TIN (200 GM)</td>
        </tr>
        <tr>
          <td>MALNAD DAYS</td>
          <td className="ft-Wt-700"> 500 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>SUTRA</td>
          <td className="ft-Wt-700"> 500 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>MONSOON MALABAR</td>
          <td className="ft-Wt-700"> 500 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>MYSORE NUGGETS</td>
          <td className="ft-Wt-700"> 500 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>JAGGERY POWDER</td>
          <td className="ft-Wt-700"> 80 </td>
          <td className="ft-Wt-700"> 250 GM</td>
        </tr>
        <tr>
          <td>INDIANO (ROASTED BEANS)</td>
          <td className="ft-Wt-700"> 500 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>PACKED SNACKS</td>
        </tr>
        <tr>
          <td>KODUBALE (SAVOURIES)</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>BOMBAY MIXTURE (SAVOURIES)</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>COATED PEANUTS (SAVOURIES)</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>MOONG DAL (SAVOURIES)</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>KHARA BOONDHI (SAVOURIES)</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>MADDUR VADA (PACK OF 5)</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">250GMS</td>
        </tr>
        <tr>
          <td>OATMEAL COOKIES (PACK OF 6)</td>
          <td className="ft-Wt-700"> 140 </td>
          <td className="ft-Wt-700">240GMS</td>
        </tr>
      </table>
    );
  },
  cafe9: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>HATTI KAPPI</td>
        </tr>
        <tr className="head">
          <td>Menu</td>
          <td>MRP</td>
          <td>GM / ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>KAAPI</td>
        </tr>
        <tr>
          <td>KAAPI</td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">180 ML</td>
        </tr>
        <tr>
          <td>KAAPI LARGE </td>
          <td className="ft-Wt-700"> 120 </td>
          <td className="ft-Wt-700">250 ML</td>
        </tr>
        <tr>
          <td>BELLA KAAPI</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">180 ML</td>
        </tr>
        <tr>
          <td>BELLA KAAPI LARGE </td>
          <td className="ft-Wt-700"> 130 </td>
          <td className="ft-Wt-700">250 ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>CHAIS</td>
        </tr>
        <tr>
          <td>CHAI</td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">180 ML</td>
        </tr>
        <tr>
          <td>CHAI</td>
          <td className="ft-Wt-700"> 120 </td>
          <td className="ft-Wt-700">250 ML</td>
        </tr>
        <tr>
          <td>GINGER CHAI</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">180 ML</td>
        </tr>
        <tr>
          <td>GINGER CHAI</td>
          <td className="ft-Wt-700"> 130 </td>
          <td className="ft-Wt-700">250 ML</td>
        </tr>
        <tr>
          <td>LEMON CHAI</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">180 ML</td>
        </tr>
        <tr>
          <td>LEMON CHAI</td>
          <td className="ft-Wt-700"> 130 </td>
          <td className="ft-Wt-700">250 ML</td>
        </tr>
        <tr>
          <td>GREEN CHAI</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">180 ML</td>
        </tr>
        <tr>
          <td>GREEN CHAI</td>
          <td className="ft-Wt-700"> 130 </td>
          <td className="ft-Wt-700">250 ML</td>
        </tr>
        <tr>
          <td>BADAM MILK</td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">180 ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>COLD BEVERAGES</td>
        </tr>
        <tr>
          <td>COLD COFFEE</td>
          <td className="ft-Wt-700"> 160 </td>
          <td className="ft-Wt-700">300 ML</td>
        </tr>
        <tr>
          <td>COLD BADAM MILK</td>
          <td className="ft-Wt-700"> 160 </td>
          <td className="ft-Wt-700">300 ML</td>
        </tr>
        <tr>
          <td>COLD LEMON CHAI</td>
          <td className="ft-Wt-700"> 160 </td>
          <td className="ft-Wt-700">300 ML</td>
        </tr>
        <tr>
          <td>CHOCOLATE COLD COFFEE</td>
          <td className="ft-Wt-700"> 180 </td>
          <td className="ft-Wt-700">300 ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>FOOD</td>
        </tr>
        <tr>
          <td>ALOO MASALA SAMOSA (2PCS)</td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">90 GMS</td>
        </tr>
        <tr>
          <td>JAIN SAMOSA (2 PCS)</td>
          <td className="ft-Wt-700"> 130 </td>
          <td className="ft-Wt-700">150 GMS</td>
        </tr>
        <tr>
          <td>SABUDANA VADA (2 PCS)</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">100GMS</td>
        </tr>
        <tr>
          <td>PANEER WRAP</td>
          <td className="ft-Wt-700"> 170 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>CHOCOLATE DOUGHNUTS (1 PC)</td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">110GMS</td>
        </tr>
        <tr>
          <td>MIX VEG WRAP</td>
          <td className="ft-Wt-700"> 150 </td>
          <td className="ft-Wt-700">200 GMS</td>
        </tr>
        <tr>
          <td>ACHARI TOFU WRAP</td>
          <td className="ft-Wt-700"> 170 </td>
          <td className="ft-Wt-700">200 GMS</td>
        </tr>
        <tr>
          <td>SPINACH & CORN SANDWICH</td>
          <td className="ft-Wt-700"> 170 </td>
          <td className="ft-Wt-700">150 GMS</td>
        </tr>
        <tr>
          <td>PEPPER MUSHROOM </td>
          <td className="ft-Wt-700"> 170 </td>
          <td className="ft-Wt-700">200 GMS</td>
        </tr>
        <tr>
          <td>TANDOORI TIKKA SUB SANDWICH</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">220GMS</td>
        </tr>
        <tr>
          <td>KAAPI BROWNIE (1 PC)</td>
          <td className="ft-Wt-700"> 120 </td>
          <td className="ft-Wt-700">100 GMS</td>
        </tr>
        <tr>
          <td>KOREAN BUNS (1PC)</td>
          <td className="ft-Wt-700"> 120 </td>
          <td className="ft-Wt-700">90 GMS</td>
        </tr>
        <tr>
          <td>BANANA CAKE (1 PC)</td>
          <td className="ft-Wt-700"> 120 </td>
          <td className="ft-Wt-700">125 GMS</td>
        </tr>
        <tr>
          <td>BLACK FOREST CAKE</td>
          <td className="ft-Wt-700"> 170 </td>
          <td className="ft-Wt-700">110GMS</td>
        </tr>
        <tr>
          <td>MUFFIN CHOCOLATE </td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">100GMS</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>6 SECONDS MERCHANDISE</td>
        </tr>
        <tr>
          <td>6 SECONDS FILTER COFFEE DECOCTION (PACK OF 1)</td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>6 SEONDS HATTI KASHAYA (PACK OF 1)</td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>6 SECONDS HATTI GOLDEN LATTE (PACK OF 1)</td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>KAAPI POWDER TUBE (250 GM)</td>
        </tr>
        <tr>
          <td>CHICORY BLEND (80:20) </td>
          <td className="ft-Wt-700"> 150 </td>
          <td className="ft-Wt-700">250GMS</td>
        </tr>
        <tr>
          <td>MYSORE KAAPI</td>
          <td className="ft-Wt-700"> 190 </td>
          <td className="ft-Wt-700">250GMS</td>
        </tr>
        <tr>
          <td>PURE KAAPI</td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">250GMS</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>KAAPI POWDER TIN (200 GM)</td>
        </tr>
        <tr>
          <td>MALNAD DAYS</td>
          <td className="ft-Wt-700"> 500 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>SUTRA</td>
          <td className="ft-Wt-700"> 500 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>MONSOON MALABAR</td>
          <td className="ft-Wt-700"> 500 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>MYSORE NUGGETS</td>
          <td className="ft-Wt-700"> 500 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>JAGGERY POWDER</td>
          <td className="ft-Wt-700"> 80 </td>
          <td className="ft-Wt-700"> 250 GM</td>
        </tr>
        <tr>
          <td>INDIANO (ROASTED BEANS)</td>
          <td className="ft-Wt-700"> 500 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>PACKED SNACKS</td>
        </tr>
        <tr>
          <td>KODUBALE (SAVOURIES)</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>BOMBAY MIXTURE (SAVOURIES)</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>COATED PEANUTS (SAVOURIES)</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>MOONG DAL (SAVOURIES)</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>KHARA BOONDHI (SAVOURIES)</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700">200GMS</td>
        </tr>
        <tr>
          <td>MADDUR VADA (PACK OF 5)</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">250GMS</td>
        </tr>
        <tr>
          <td>OATMEAL COOKIES (PACK OF 6)</td>
          <td className="ft-Wt-700"> 140 </td>
          <td className="ft-Wt-700">240GMS</td>
        </tr>
      </table>
    );
  },
  cafe10: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>SOCIETY TEA TERMINAL</td>
        </tr>
        <tr className="head">
          <td>Menu</td>
          <td>MRP</td>
          <td>GM / ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>TEA</td>
        </tr>
        <tr>
          <td>REGULER TEA </td>
          <td className="ft-Wt-700">50</td>
          <td className="ft-Wt-700">100ML</td>
        </tr>
        <tr>
          <td>MASALA TEA </td>
          <td className="ft-Wt-700">60</td>
          <td className="ft-Wt-700">100ML</td>
        </tr>
        <tr>
          <td>ELAICHI TEA </td>
          <td className="ft-Wt-700">60</td>
          <td className="ft-Wt-700">100ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>RTD ICED TEA</td>
        </tr>
        <tr>
          <td>PEACH & APRICOT </td>
          <td className="ft-Wt-700">60</td>
          <td className="ft-Wt-700">250ML</td>
        </tr>
        <tr>
          <td>LEMON </td>
          <td className="ft-Wt-700">60</td>
          <td className="ft-Wt-700">250ML</td>
        </tr>
        <tr>
          <td>GINGER CINNAMON BERGAMOT </td>
          <td className="ft-Wt-700">60</td>
          <td className="ft-Wt-700">250ML</td>
        </tr>
        <tr>
          <td>CAMAMILE JASMINE </td>
          <td className="ft-Wt-700">60</td>
          <td className="ft-Wt-700">250ML</td>
        </tr>
        <tr>
          <td>STRAWBERRY KIWI LEMONGRASS </td>
          <td className="ft-Wt-700">60</td>
          <td className="ft-Wt-700">250ML</td>
        </tr>
        <tr>
          <td>2 BOTTEL ANY FLAVOUR </td>
          <td className="ft-Wt-700">100</td>
          <td className="ft-Wt-700">500ML</td>
        </tr>
        <tr>
          <td>4 BOTTEL ANY FLAVOUR </td>
          <td className="ft-Wt-700">200</td>
          <td className="ft-Wt-700">1000ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>COFFEE</td>
        </tr>
        <tr>
          <td>COLD COFFEE </td>
          <td className="ft-Wt-700">100</td>
          <td className="ft-Wt-700">250ML</td>
        </tr>
        <tr>
          <td>CAPPUCCINO </td>
          <td className="ft-Wt-700">60</td>
          <td className="ft-Wt-700">100ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>DETOX SERIES</td>
        </tr>
        <tr>
          <td>GREEN TEA </td>
          <td className="ft-Wt-700">60</td>
          <td className="ft-Wt-700">200ML</td>
        </tr>
        <tr>
          <td>KASMIRI KAHWA </td>
          <td className="ft-Wt-700">60</td>
          <td className="ft-Wt-700">200ML</td>
        </tr>
        <tr>
          <td>TULSI RITUAL </td>
          <td className="ft-Wt-700">60</td>
          <td className="ft-Wt-700">200ML</td>
        </tr>
        <tr>
          <td>MOROCCAN MINT </td>
          <td className="ft-Wt-700">60</td>
          <td className="ft-Wt-700">200ML</td>
        </tr>
        <tr>
          <td>FLOWERY BOUQUET </td>
          <td className="ft-Wt-700">60</td>
          <td className="ft-Wt-700">200ML</td>
        </tr>
        <tr>
          <td>SOOTHING CHAMOMILE </td>
          <td className="ft-Wt-700">60</td>
          <td className="ft-Wt-700">200ML</td>
        </tr>
        <tr>
          <td>DIVINE DARJEELING </td>
          <td className="ft-Wt-700">60</td>
          <td className="ft-Wt-700">200ML</td>
        </tr>
        <tr>
          <td>CLEANSE </td>
          <td className="ft-Wt-700">60</td>
          <td className="ft-Wt-700">100ML</td>
        </tr>
        <tr>
          <td>HALDI DOODH </td>
          <td className="ft-Wt-700">60</td>
          <td className="ft-Wt-700">100ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>SNACKS</td>
        </tr>
        <tr>
          <td>KHARI (3 PCS) </td>
          <td className="ft-Wt-700">40</td>
          <td className="ft-Wt-700">3 PCS</td>
        </tr>
        <tr>
          <td>TOAST (3 PCS) </td>
          <td className="ft-Wt-700">40</td>
          <td className="ft-Wt-700">3 PCS</td>
        </tr>
        <tr>
          <td>BUN JAM MASKA </td>
          <td className="ft-Wt-700">60</td>
          <td className="ft-Wt-700">1 PC</td>
        </tr>
        <tr>
          <td>POHA </td>
          <td className="ft-Wt-700">70</td>
          <td className="ft-Wt-700">80 GM</td>
        </tr>
        <tr>
          <td>UPMA </td>
          <td className="ft-Wt-700">70</td>
          <td className="ft-Wt-700">80 GM</td>
        </tr>
      </table>
    );
  },
  cafe11: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>HIGHNESS PANINI</td>
        </tr>
        <tr className="head">
          <td>Menu</td>
          <td>MRP</td>
          <td>GM / ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>SNACKS & BAKERY</td>
        </tr>
        <tr>
          <td>MEXICAN (JAIN/REGULAR)</td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">200 GMS</td>
        </tr>
        <tr>
          <td>PANEER TIKKA</td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">200 GMS</td>
        </tr>
        <tr>
          <td>CHEESE MELT PANINI (JAIN/REGULAR)</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">220 GMS</td>
        </tr>
        <tr>
          <td>CHEESE MAYO GARLIC BREAD</td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">120 GMS </td>
        </tr>
        <tr>
          <td>VEG GRILLED SANDWICH (JAIN/REGULAR)</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">220 GMS</td>
        </tr>
        <tr>
          <td>MELTED SANDWICH (JAIN/REGULAR)</td>
          <td className="ft-Wt-700"> 300 </td>
          <td className="ft-Wt-700">250 GMS</td>
        </tr>
        <tr>
          <td>CHEESE CHILLI SANDWICH </td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">220 GMS</td>
        </tr>
        <tr>
          <td>MASKA BUN</td>
          <td className="ft-Wt-700"> 80 </td>
          <td className="ft-Wt-700">50 GMS</td>
        </tr>
        <tr>
          <td>JAM BUN</td>
          <td className="ft-Wt-700"> 80 </td>
          <td className="ft-Wt-700">50 GMS</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>PIZZA</td>
        </tr>
        <tr>
          <td>MARGARITA PIZZA (JAIN/REGULAR)</td>
          <td className="ft-Wt-700"> 280 </td>
          <td className="ft-Wt-700">140 GMS </td>
        </tr>
        <tr>
          <td>PEPPY PANEER PIZZA (JAIN/REGULAR)</td>
          <td className="ft-Wt-700"> 300 </td>
          <td className="ft-Wt-700">170 GMS </td>
        </tr>
        <tr>
          <td>FARMHOUSE PIZZA (JAIN/REGULAR)</td>
          <td className="ft-Wt-700"> 300 </td>
          <td className="ft-Wt-700">170 GMS </td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>ROLL</td>
        </tr>
        <tr>
          <td>PANEER TIKKA ROLL</td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">150 GMS</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>HOT BEVERAGES</td>
        </tr>
        <tr>
          <td>REGULAR ADRAK TEA</td>
          <td className="ft-Wt-700"> 50 </td>
          <td className="ft-Wt-700">100 ML</td>
        </tr>
        <tr>
          <td>MASALA TEA</td>
          <td className="ft-Wt-700"> 70 </td>
          <td className="ft-Wt-700">100 ML</td>
        </tr>
        <tr>
          <td>BLACK TEA</td>
          <td className="ft-Wt-700"> 50 </td>
          <td className="ft-Wt-700">100 ML</td>
        </tr>
        <tr>
          <td>LEMON TEA</td>
          <td className="ft-Wt-700"> 70 </td>
          <td className="ft-Wt-700">150 ML</td>
        </tr>
        <tr>
          <td>GREEN TEA</td>
          <td className="ft-Wt-700"> 70 </td>
          <td className="ft-Wt-700">150 ML</td>
        </tr>
        <tr>
          <td>FILTER COFFEE</td>
          <td className="ft-Wt-700"> 80 </td>
          <td className="ft-Wt-700">100ML</td>
        </tr>
        <tr>
          <td>CAFE AMERICANO</td>
          <td className="ft-Wt-700"> 150 </td>
          <td className="ft-Wt-700">200ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>COLD BEVERAGES</td>
        </tr>
        <tr>
          <td>SIGNATURE MOCHA COLD COFFEE </td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">300ML</td>
        </tr>
        <tr>
          <td>SIGNATURE COLD COFFEE </td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">300ML</td>
        </tr>
        <tr>
          <td>SIGNATURE ICE AMERICANO</td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">300ML</td>
        </tr>
        <tr>
          <td>LEMON ICED TEA</td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">300ML</td>
        </tr>
        <tr>
          <td>PEACH ICED TEA</td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">300ML</td>
        </tr>
      </table>
    );
  },
  cafe12: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>CHAI POINT</td>
        </tr>
        <tr className="head">
          <td>Menu</td>
          <td>MRP</td>
          <td>GM / ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>HOT BEVERAGES</td>
        </tr>
        <tr>
          <td>CAPPUCINNO</td>
          <td className="ft-Wt-700"> 180 </td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>CAFÉ LATTE</td>
          <td className="ft-Wt-700"> 180 </td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>CAFÉ MOCHA</td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>AMERICANO</td>
          <td className="ft-Wt-700"> 180 </td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>HOT CHOCOLATE</td>
          <td className="ft-Wt-700"> 160 </td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>MASALA TEA</td>
          <td className="ft-Wt-700"> 60 </td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>HOT FILTER COFFEE </td>
          <td className="ft-Wt-700"> 70 </td>
          <td className="ft-Wt-700">130</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>COLD BEVERAGES</td>
        </tr>
        <tr>
          <td>COLD COFFEE</td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">300</td>
        </tr>
        <tr>
          <td>ICE CHOCOLATE </td>
          <td className="ft-Wt-700"> 150 </td>
          <td className="ft-Wt-700">300</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>SANDWICH</td>
        </tr>
        <tr>
          <td>VEG GRILLED SANDWICH </td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>VEG CHEESE GRILLED S/W (JAIN /REGULAR)</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>MELTED CHILI CHEESE TOAST S/W</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>MAXICAN PANEER GRILL S/W</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">170</td>
        </tr>
        <tr>
          <td>PANEER TIKKA WRAP</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">170</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>PIZZA</td>
        </tr>
        <tr>
          <td>DELUX VEGGIE PIZZA (JAIN / REGULAR)</td>
          <td className="ft-Wt-700"> 270 </td>
          <td className="ft-Wt-700">180</td>
        </tr>
        <tr>
          <td>CLASSIC MARGRITA PIZZA (JAIN / REGULAR)</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">180</td>
        </tr>
        <tr>
          <td>PEPPY PANEER CHEESE BURST PIZZA</td>
          <td className="ft-Wt-700"> 300 </td>
          <td className="ft-Wt-700">200</td>
        </tr>
      </table>
    );
  },
  cafe13: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>CHAI POINT</td>
        </tr>
        <tr className="head">
          <td>Menu</td>
          <td>MRP</td>
          <td>GM / ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>HOT BEVERAGES</td>
        </tr>
        <tr>
          <td>CAPPUCINNO</td>
          <td className="ft-Wt-700"> 180 </td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>CAFÉ LATTE</td>
          <td className="ft-Wt-700"> 180 </td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>CAFÉ MOCHA</td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>AMERICANO</td>
          <td className="ft-Wt-700"> 180 </td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>HOT CHOCOLATE</td>
          <td className="ft-Wt-700"> 160 </td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>MASALA TEA</td>
          <td className="ft-Wt-700"> 60 </td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>HOT FILTER COFFEE </td>
          <td className="ft-Wt-700"> 70 </td>
          <td className="ft-Wt-700">130</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>COLD BEVERAGES</td>
        </tr>
        <tr>
          <td>COLD COFFEE</td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">300</td>
        </tr>
        <tr>
          <td>ICE CHOCOLATE </td>
          <td className="ft-Wt-700"> 150 </td>
          <td className="ft-Wt-700">300</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>SANDWICH</td>
        </tr>
        <tr>
          <td>VEG GRILLED SANDWICH </td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>VEG CHEESE GRILLED S/W (JAIN /REGULAR)</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>MELTED CHILI CHEESE TOAST S/W</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>MAXICAN PANEER GRILL S/W</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">170</td>
        </tr>
        <tr>
          <td>PANEER TIKKA WRAP</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">170</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>PIZZA</td>
        </tr>
        <tr>
          <td>DELUX VEGGIE PIZZA (JAIN / REGULAR)</td>
          <td className="ft-Wt-700"> 270 </td>
          <td className="ft-Wt-700">180</td>
        </tr>
        <tr>
          <td>CLASSIC MARGRITA PIZZA (JAIN / REGULAR)</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">180</td>
        </tr>
        <tr>
          <td>PEPPY PANEER CHEESE BURST PIZZA</td>
          <td className="ft-Wt-700"> 300 </td>
          <td className="ft-Wt-700">200</td>
        </tr>
      </table>
    );
  },
  cafe14: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>BARISTA</td>
        </tr>
        <tr className="head">
          <td>Menu</td>
          <td>MRP</td>
          <td>GM / ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>BEVERAGES</td>
        </tr>
        <tr>
          <td>ESPRESSO ITALIANO </td>
          <td className="ft-Wt-700"> 160 </td>
          <td className="ft-Wt-700">200ml</td>
        </tr>
        <tr>
          <td>ESPRESSO ITALIANO </td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">300ml</td>
        </tr>
        <tr>
          <td>CAFÉ AMERICANO </td>
          <td className="ft-Wt-700"> 180 </td>
          <td className="ft-Wt-700">200ml</td>
        </tr>
        <tr>
          <td>CAFÉ AMERICANO </td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">300ml</td>
        </tr>
        <tr>
          <td>CAFÉ LATTE </td>
          <td className="ft-Wt-700"> 220 </td>
          <td className="ft-Wt-700">200ml</td>
        </tr>
        <tr>
          <td>CAFÉ LATTE </td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">300ml</td>
        </tr>
        <tr>
          <td>CAPPUCCINO </td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">200ml</td>
        </tr>
        <tr>
          <td>CAPPUCCINO </td>
          <td className="ft-Wt-700"> 230 </td>
          <td className="ft-Wt-700">300ml</td>
        </tr>
        <tr>
          <td>CAFÉ MOCHA </td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">200ml</td>
        </tr>
        <tr>
          <td>CAFÉ MOCHA </td>
          <td className="ft-Wt-700"> 300 </td>
          <td className="ft-Wt-700">300ml</td>
        </tr>
        <tr>
          <td>HOT CHOCOLATE </td>
          <td className="ft-Wt-700"> 260 </td>
          <td className="ft-Wt-700">200ml</td>
        </tr>
        <tr>
          <td>HOT CHOCOLATE </td>
          <td className="ft-Wt-700"> 300 </td>
          <td className="ft-Wt-700">300ml</td>
        </tr>
        <tr>
          <td>FLAT WHITE </td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">200ml</td>
        </tr>
        <tr>
          <td>FLAT WHITE </td>
          <td className="ft-Wt-700"> 280 </td>
          <td className="ft-Wt-700">300ml</td>
        </tr>
        <tr>
          <td>BARISTA BLAST</td>
          <td className="ft-Wt-700"> 360 </td>
          <td className="ft-Wt-700">360ml</td>
        </tr>
        <tr>
          <td>BARISTA BLAST</td>
          <td className="ft-Wt-700"> 400 </td>
          <td className="ft-Wt-700">420ml</td>
        </tr>
        <tr>
          <td>BARISTA FRAPPE</td>
          <td className="ft-Wt-700"> 300 </td>
          <td className="ft-Wt-700">360ml</td>
        </tr>
        <tr>
          <td>BARISTA FRAPPE</td>
          <td className="ft-Wt-700"> 350 </td>
          <td className="ft-Wt-700">420ml</td>
        </tr>
        <tr>
          <td>CHOCOLATE SMOOTHIE</td>
          <td className="ft-Wt-700"> 300 </td>
          <td className="ft-Wt-700">360ml</td>
        </tr>
        <tr>
          <td>CHOCOLATE SMOOTHIE</td>
          <td className="ft-Wt-700"> 350 </td>
          <td className="ft-Wt-700">420ml</td>
        </tr>
        <tr>
          <td>GINGER HONEY</td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">200ml</td>
        </tr>
        <tr>
          <td>BARISTA</td>
          <td className="ft-Wt-700"> 220 </td>
          <td className="ft-Wt-700">360ml</td>
        </tr>
        <tr>
          <td>BARISTA</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">420ml</td>
        </tr>
        <tr>
          <td>ICED AMERICANO</td>
          <td className="ft-Wt-700"> 200 </td>
          <td className="ft-Wt-700">360ml</td>
        </tr>
        <tr>
          <td>ICED AMERICANO</td>
          <td className="ft-Wt-700"> 230 </td>
          <td className="ft-Wt-700">420ml</td>
        </tr>
        <tr>
          <td>ICED CAFÉ MOCHA </td>
          <td className="ft-Wt-700"> 280 </td>
          <td className="ft-Wt-700">360ml</td>
        </tr>
        <tr>
          <td>ICED CAFÉ MOCHA </td>
          <td className="ft-Wt-700"> 300 </td>
          <td className="ft-Wt-700">420ml</td>
        </tr>
        <tr>
          <td>ICED LATTE</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">360ml</td>
        </tr>
        <tr>
          <td>ICED LATTE</td>
          <td className="ft-Wt-700"> 280 </td>
          <td className="ft-Wt-700">420ml</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>FOOD</td>
        </tr>
        <tr>
          <td>PANEER TIKKA SANDWICH </td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">200gm</td>
        </tr>
        <tr>
          <td>SPINACH & CORN SANDWICH</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">200gm</td>
        </tr>
        <tr>
          <td>DARK TEMPTATION</td>
          <td className="ft-Wt-700"> 350 </td>
          <td className="ft-Wt-700">220gm</td>
        </tr>
        <tr>
          <td>CHOCOLATE EXCESS</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">125gm</td>
        </tr>
        <tr>
          <td>ACHARI PANEER ROLL </td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">200ml</td>
        </tr>
        <tr>
          <td>BLUEBERRY MUFFIN</td>
          <td className="ft-Wt-700"> 220 </td>
          <td className="ft-Wt-700">120gm</td>
        </tr>
        <tr>
          <td>CHOCO CHIP MUFFIN</td>
          <td className="ft-Wt-700"> 220 </td>
          <td className="ft-Wt-700">120gm</td>
        </tr>
        <tr>
          <td> ALMOND MUFFIN</td>
          <td className="ft-Wt-700"> 220 </td>
          <td className="ft-Wt-700">120gm</td>
        </tr>
        <tr>
          <td>BROWNIE FONDENTE</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">100gm</td>
        </tr>
        <tr>
          <td>MARBLE CAKE </td>
          <td className="ft-Wt-700"> 150 </td>
          <td className="ft-Wt-700">100gm</td>
        </tr>
        <tr>
          <td>WICKED BROWNIE</td>
          <td className="ft-Wt-700"> 350 </td>
          <td className="ft-Wt-700">195gm</td>
        </tr>
        <tr>
          <td>DOUBLE CHOCOLATE CHIP COOKIE</td>
          <td className="ft-Wt-700"> 130 </td>
          <td className="ft-Wt-700">50gm</td>
        </tr>
        <tr>
          <td>CHOCOLATE EXCESS -SLICE</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700">125gm</td>
        </tr>
        <tr>
          <td>VEGETARIAN PUFF</td>
          <td className="ft-Wt-700"> 150 </td>
          <td className="ft-Wt-700">110gm</td>
        </tr>
        <tr>
          <td>THREE PEPPER CHEESE TOASTY</td>
          <td className="ft-Wt-700"> 160 </td>
          <td className="ft-Wt-700">120gm</td>
        </tr>
        <tr>
          <td>OATMEAL RAISIN COOKIE</td>
          <td className="ft-Wt-700"> 130 </td>
          <td className="ft-Wt-700">50gm</td>
        </tr>
      </table>
    );
  },
  cafe15: () => {
    return (
      <>
        <table style={{ width: "100%" }}>
          <tr className="title">
            <td colSpan={3}>SUBWAY</td>
          </tr>
          <tr className="head">
            <td>Menu</td>
            <td>MRP</td>
            <td>GM / ML</td>
          </tr>
          <tr>
            <td>PANNER TIKKA</td>
            <td className="ft-Wt-700"> 300 </td>
            <td className="ft-Wt-700"></td>
          </tr>
          <tr>
            <td>VEGGIE DELITE</td>
            <td className="ft-Wt-700"> 250 </td>
            <td className="ft-Wt-700"></td>
          </tr>
          <tr>
            <td>ALOO PATTY</td>
            <td className="ft-Wt-700"> 300 </td>
            <td className="ft-Wt-700"></td>
          </tr>
          <tr>
            <td>CHILLI BEANS PATTY</td>
            <td className="ft-Wt-700"> 300 </td>
            <td className="ft-Wt-700"></td>
          </tr>
          <tr>
            <td>COOKIE</td>
            <td className="ft-Wt-700"> 100 </td>
            <td className="ft-Wt-700"></td>
          </tr>
        </table>
        <table>
          <tr className="title">
            <td colSpan={3}>HATTI KAPPI</td>
          </tr>
          <tr className="head">
            <td>Menu</td>
            <td>MRP</td>
            <td>GM / ML</td>
          </tr>
          <tr className="subHead">
            <td colSpan={3}>KAAPI</td>
          </tr>
          <tr>
            <td>KAAPI</td>
            <td className="ft-Wt-700"> 100 </td>
            <td className="ft-Wt-700">180 ML</td>
          </tr>
          <tr>
            <td>KAAPI LARGE </td>
            <td className="ft-Wt-700"> 120 </td>
            <td className="ft-Wt-700">250 ML</td>
          </tr>
          <tr>
            <td>BELLA KAAPI</td>
            <td className="ft-Wt-700"> 110 </td>
            <td className="ft-Wt-700">180 ML</td>
          </tr>
          <tr>
            <td>BELLA KAAPI LARGE </td>
            <td className="ft-Wt-700"> 130 </td>
            <td className="ft-Wt-700">250 ML</td>
          </tr>
          <tr className="subHead">
            <td colSpan={3}>CHAIS</td>
          </tr>
          <tr>
            <td>CHAI</td>
            <td className="ft-Wt-700"> 100 </td>
            <td className="ft-Wt-700">180 ML</td>
          </tr>
          <tr>
            <td>CHAI</td>
            <td className="ft-Wt-700"> 120 </td>
            <td className="ft-Wt-700">250 ML</td>
          </tr>
          <tr>
            <td>GINGER CHAI</td>
            <td className="ft-Wt-700"> 110 </td>
            <td className="ft-Wt-700">180 ML</td>
          </tr>
          <tr>
            <td>GINGER CHAI</td>
            <td className="ft-Wt-700"> 130 </td>
            <td className="ft-Wt-700">250 ML</td>
          </tr>
          <tr>
            <td>LEMON CHAI</td>
            <td className="ft-Wt-700"> 110 </td>
            <td className="ft-Wt-700">180 ML</td>
          </tr>
          <tr>
            <td>LEMON CHAI</td>
            <td className="ft-Wt-700"> 130 </td>
            <td className="ft-Wt-700">250 ML</td>
          </tr>
          <tr>
            <td>GREEN CHAI</td>
            <td className="ft-Wt-700"> 110 </td>
            <td className="ft-Wt-700">180 ML</td>
          </tr>
          <tr>
            <td>GREEN CHAI</td>
            <td className="ft-Wt-700"> 130 </td>
            <td className="ft-Wt-700">250 ML</td>
          </tr>
          <tr>
            <td>BADAM MILK</td>
            <td className="ft-Wt-700"> 100 </td>
            <td className="ft-Wt-700">180 ML</td>
          </tr>
          <tr className="subHead">
            <td colSpan={3}>COLD BEVERAGES</td>
          </tr>
          <tr>
            <td>COLD COFFEE</td>
            <td className="ft-Wt-700"> 160 </td>
            <td className="ft-Wt-700">300 ML</td>
          </tr>
          <tr>
            <td>COLD BADAM MILK</td>
            <td className="ft-Wt-700"> 160 </td>
            <td className="ft-Wt-700">300 ML</td>
          </tr>
          <tr>
            <td>COLD LEMON CHAI</td>
            <td className="ft-Wt-700"> 160 </td>
            <td className="ft-Wt-700">300 ML</td>
          </tr>
          <tr>
            <td>CHOCOLATE COLD COFFEE</td>
            <td className="ft-Wt-700"> 180 </td>
            <td className="ft-Wt-700">300 ML</td>
          </tr>
          <tr className="subHead">
            <td colSpan={3}>FOOD</td>
          </tr>
          <tr>
            <td>ALOO MASALA SAMOSA (2PCS)</td>
            <td className="ft-Wt-700"> 100 </td>
            <td className="ft-Wt-700">90 GMS</td>
          </tr>
          <tr>
            <td>JAIN SAMOSA (2 PCS)</td>
            <td className="ft-Wt-700"> 130 </td>
            <td className="ft-Wt-700">150 GMS</td>
          </tr>
          <tr>
            <td>SABUDANA VADA (2 PCS)</td>
            <td className="ft-Wt-700"> 110 </td>
            <td className="ft-Wt-700">100GMS</td>
          </tr>
          <tr>
            <td>PANEER WRAP</td>
            <td className="ft-Wt-700"> 170 </td>
            <td className="ft-Wt-700">200GMS</td>
          </tr>
          <tr>
            <td>CHOCOLATE DOUGHNUTS (1 PC)</td>
            <td className="ft-Wt-700"> 100 </td>
            <td className="ft-Wt-700">110GMS</td>
          </tr>
          <tr>
            <td>MIX VEG WRAP</td>
            <td className="ft-Wt-700"> 150 </td>
            <td className="ft-Wt-700">200 GMS</td>
          </tr>
          <tr>
            <td>ACHARI TOFU WRAP</td>
            <td className="ft-Wt-700"> 170 </td>
            <td className="ft-Wt-700">200 GMS</td>
          </tr>
          <tr>
            <td>SPINACH & CORN SANDWICH</td>
            <td className="ft-Wt-700"> 170 </td>
            <td className="ft-Wt-700">150 GMS</td>
          </tr>
          <tr>
            <td>PEPPER MUSHROOM </td>
            <td className="ft-Wt-700"> 170 </td>
            <td className="ft-Wt-700">200 GMS</td>
          </tr>
          <tr>
            <td>TANDOORI TIKKA SUB SANDWICH</td>
            <td className="ft-Wt-700"> 250 </td>
            <td className="ft-Wt-700">220GMS</td>
          </tr>
          <tr>
            <td>KAAPI BROWNIE (1 PC)</td>
            <td className="ft-Wt-700"> 120 </td>
            <td className="ft-Wt-700">100 GMS</td>
          </tr>
          <tr>
            <td>KOREAN BUNS (1PC)</td>
            <td className="ft-Wt-700"> 120 </td>
            <td className="ft-Wt-700">90 GMS</td>
          </tr>
          <tr>
            <td>BANANA CAKE (1 PC)</td>
            <td className="ft-Wt-700"> 120 </td>
            <td className="ft-Wt-700">125 GMS</td>
          </tr>
          <tr>
            <td>BLACK FOREST CAKE</td>
            <td className="ft-Wt-700"> 170 </td>
            <td className="ft-Wt-700">110GMS</td>
          </tr>
          <tr>
            <td>MUFFIN CHOCOLATE </td>
            <td className="ft-Wt-700"> 100 </td>
            <td className="ft-Wt-700">100GMS</td>
          </tr>
          <tr className="subHead">
            <td colSpan={3}>6 SECONDS MERCHANDISE</td>
          </tr>
          <tr>
            <td>6 SECONDS FILTER COFFEE DECOCTION (PACK OF 1)</td>
            <td className="ft-Wt-700"> 100 </td>
            <td className="ft-Wt-700">200GMS</td>
          </tr>
          <tr>
            <td>6 SEONDS HATTI KASHAYA (PACK OF 1)</td>
            <td className="ft-Wt-700"> 100 </td>
            <td className="ft-Wt-700">200GMS</td>
          </tr>
          <tr>
            <td>6 SECONDS HATTI GOLDEN LATTE (PACK OF 1)</td>
            <td className="ft-Wt-700"> 100 </td>
            <td className="ft-Wt-700">200GMS</td>
          </tr>
          <tr className="subHead">
            <td colSpan={3}>KAAPI POWDER TUBE (250 GM)</td>
          </tr>
          <tr>
            <td>CHICORY BLEND (80:20) </td>
            <td className="ft-Wt-700"> 150 </td>
            <td className="ft-Wt-700">250GMS</td>
          </tr>
          <tr>
            <td>MYSORE KAAPI</td>
            <td className="ft-Wt-700"> 190 </td>
            <td className="ft-Wt-700">250GMS</td>
          </tr>
          <tr>
            <td>PURE KAAPI</td>
            <td className="ft-Wt-700"> 200 </td>
            <td className="ft-Wt-700">250GMS</td>
          </tr>
          <tr className="subHead">
            <td colSpan={3}>KAAPI POWDER TIN (200 GM)</td>
          </tr>
          <tr>
            <td>MALNAD DAYS</td>
            <td className="ft-Wt-700"> 500 </td>
            <td className="ft-Wt-700">200GMS</td>
          </tr>
          <tr>
            <td>SUTRA</td>
            <td className="ft-Wt-700"> 500 </td>
            <td className="ft-Wt-700">200GMS</td>
          </tr>
          <tr>
            <td>MONSOON MALABAR</td>
            <td className="ft-Wt-700"> 500 </td>
            <td className="ft-Wt-700">200GMS</td>
          </tr>
          <tr>
            <td>MYSORE NUGGETS</td>
            <td className="ft-Wt-700"> 500 </td>
            <td className="ft-Wt-700">200GMS</td>
          </tr>
          <tr>
            <td>JAGGERY POWDER</td>
            <td className="ft-Wt-700"> 80 </td>
            <td className="ft-Wt-700"> 250 GM</td>
          </tr>
          <tr>
            <td>INDIANO (ROASTED BEANS)</td>
            <td className="ft-Wt-700"> 500 </td>
            <td className="ft-Wt-700">200GMS</td>
          </tr>
          <tr className="subHead">
            <td colSpan={3}>PACKED SNACKS</td>
          </tr>
          <tr>
            <td>KODUBALE (SAVOURIES)</td>
            <td className="ft-Wt-700"> 110 </td>
            <td className="ft-Wt-700">200GMS</td>
          </tr>
          <tr>
            <td>BOMBAY MIXTURE (SAVOURIES)</td>
            <td className="ft-Wt-700"> 110 </td>
            <td className="ft-Wt-700">200GMS</td>
          </tr>
          <tr>
            <td>COATED PEANUTS (SAVOURIES)</td>
            <td className="ft-Wt-700"> 110 </td>
            <td className="ft-Wt-700">200GMS</td>
          </tr>
          <tr>
            <td>MOONG DAL (SAVOURIES)</td>
            <td className="ft-Wt-700"> 110 </td>
            <td className="ft-Wt-700">200GMS</td>
          </tr>
          <tr>
            <td>KHARA BOONDHI (SAVOURIES)</td>
            <td className="ft-Wt-700"> 110 </td>
            <td className="ft-Wt-700">200GMS</td>
          </tr>
          <tr>
            <td>MADDUR VADA (PACK OF 5)</td>
            <td className="ft-Wt-700"> 250 </td>
            <td className="ft-Wt-700">250GMS</td>
          </tr>
          <tr>
            <td>OATMEAL COOKIES (PACK OF 6)</td>
            <td className="ft-Wt-700"> 140 </td>
            <td className="ft-Wt-700">240GMS</td>
          </tr>
        </table>
      </>
    );
  },
};
